.grid1{

  display: grid;
  grid-template-columns:  1fr 1fr 1fr;
  grid-template-rows: repeat( 4, auto  );

  column-gap: 20px;
  row-gap: 20px;

  margin-right: auto;
  margin-left: auto;

  margin-bottom: 40px;

  grid-auto-flow: dense;
}

.gb{
  /* border:1px solid orange; */
}

.gb-1{
  grid-column: 1/3;
  grid-row: 1/2;
}

.gb-2{
  grid-column: 3/4;
  grid-row: 1/2;
}

.gb-3{
  grid-column: 1/2;
  grid-row: 2/3;
}

.gb-4{
  grid-column: 2/3;
  grid-row: 2/4;
}

.gb-5{
  grid-column: 3/4;
  grid-row: 2/3;
}

.gb-6{
  grid-column: 1/2;
  grid-row: 3/5;
}

.gb-7{
  grid-column: 2/3;
  grid-row: 4/5;
}

.gb-8{
  grid-column: 3/4;
  grid-row: 3/5;
}



@media (max-width: 1400px) {
  .grid1{
    grid-template-columns:  1fr 1fr;

  }


  .gb-2{
    grid-column:1/2;
    grid-row:2/3;
  }

  .gb-3{
    grid-column:2/3;
    grid-row:4/5;
  }

  .gb-4{
    grid-column:2/3;
    grid-row:2/4;
  }

  .gb-5{
    grid-column:1/2;
    grid-row:5/6;
  }

  .gb-6{
    grid-column:1/2;
    grid-row:3/5;
  }


  .gb-7{
    grid-column:1/2;
    grid-row:6/7;
  }

  .gb-8{
    grid-column:2/3;
    grid-row:5/7;
  }

}


@media (max-width: 768px) {

  .grid1{
    grid-template-columns:  1fr;
    grid-template-rows: auto;
  }

  .gb{
    grid-column: auto;
    grid-row: auto;
  }

  .gb-1{
    grid-column:1/2;
    grid-row:1/2;
  }

}

@media (max-width: 576px) {

}


.home{


  .block{
    min-height:125px;
    padding:15px;
    font-size:12px;
    border-radius: 10px;
    background-color: white;
    overflow:hidden;
  }

  .block-image{
    /* margin:0 0 15px 0; */
    padding:0px;
    background-size: cover;
    background-repeat: no-repeat;
    /* height:258px; */
    width:auto;

    aspect-ratio: 500 / 323;
    display: flex;
    text-decoration: none;
    flex-direction:  column;
    justify-content: center;
    text-align: center;

    h3{
      color:white;
    }

    .mask {
      background-image: linear-gradient(to top, #35302f, rgba(53, 48, 47, 0));
      height: inherit;
      opacity: 0.75;
      background-size: cover;
      position: relative;
      height:100%;
    }

    .text{
      position: absolute;
      right: 0;
      left: 0;
      bottom: 20px;
    }

  }

  .block-promo{
    padding:0px;
    background-size: contain;

    /* height:323px; */

    width:auto;

    aspect-ratio: 1019 / 323;


    text-decoration: none;
    text-align: right;
    color: #442c5d;
    position: relative;

    .bg{
      position: absolute;
      top:0;
      bottom:0;
      right:0;
      left:0;
      z-index:1;
      background-repeat: no-repeat;
      background-size: contain;
      //background-image: url('/online-brand-guidelines1.png');
      //background-position: bottom left;
      /* @media (max-width: 1730px) {
        background-size: 40%;
      } */
    }


    .gbg{
      z-index:2;
      position: absolute;
      top:0;
      bottom:0;
      right:0;
      left:0;
      opacity: 0.25;
      background-image: linear-gradient(to bottom, #302e2f, rgba(116, 115, 116, 0));
    }

    .text1{
      position: absolute;
      top:2rem;

      right:2rem;
      text-align: right;
      z-index:3;
    }

    .text2{
      position: absolute;
      bottom:1rem;
      right:2rem;
      z-index:15;
    }

    .link{
      position: absolute;
      top:0;
      bottom:0;
      right:0;
      left:0;
      z-index:16;
    }

    h3{
      /* color:white; */
      max-width: 500px;
      font-size: 47px;
      font-weight: 900;
      text-align: right;
      font-family: 'Mont Black';
      //font-weight:bold;
    }


    .subtitle{
      font-size: 30px;
      font-weight: bold;
      color: #442c5d;
      text-shadow: 0 3px 24px rgba(0, 0, 0, 0.19);
      //font-family: FrankRuhlLibre-Bold;
    }

    a{
      text-decoration: none;
      color: #442c5d;
      font-size:22px;

      img{
        margin-left: 10px;
      }
    }
  }



  .block-link{
    text-decoration: none;
    color:white;
  }

  h4{
    font-size:22px;
  }

  h3{
    font-size:30px;
    font-weight: normal;
    /*color:#442c5d;*/
  }
}

.block-product{

  min-height: 125px;
  /* border: 1px solid grey; */
  padding: 15px;
  margin: 0 0 0 0;
  /* margin: 15px; */
  font-size: 12px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  position: relative;


  .corner{
    /* border:1px solid red; */
    height:200px;
    width:200px;
    position: absolute;
    top:0;
    right:-1px;
    background-image: url('../../images/corner.png');
    background-repeat: no-repeat;
    background-position: right top;
    opacity: 0.8;
  }

  h3{
    /* color:#442c5c; */
    font-size:39px;
  }

  h4{
    /* margin-bottom: 0;
    padding-bottom: 0; */
    color: #35302f;
  }

  .block-header{
    margin-top: 55px;
    margin-bottom: 24px;
    min-height:120px;
  }

  .block-body{
    height:312px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ebe9e8 ; // #808080; // rgba(90, 90, 90);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent; // rgba(0, 0, 0, 0.2);
    }


  }
}

.card-hp-product{
  /*
  border:1px dotted green;
   */
  border:none !important;
  border-bottom: 1px solid #dedede !important;
  padding-top: 6px;
  padding-bottom: 8px;
  margin-right: 30px;
  border-radius: 0;

  .card-body{
    padding: 0;
    /* padding-top: 4px;
    padding-bottom: 6px; */

    a{
      text-decoration: none;
      font-size:20px;
      color:#302e2f;
    }

    .grey{
      color: #929292;
      font-size:14px;
    }
  }




  .language-chips{
    display: inline-block;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.card-first{
  padding-top: 0;
}

.card-last{
  border-bottom:none !important;
  padding-bottom: 0;
}


@media (max-width: 1240px) {
/*   .home{
    .block-image{
      height:206px;
      width:320px;
    }
  } */
}

@media (max-width: 768px) {
  .home{

    .block-promo{
      aspect-ratio:initial; // 406 / 265;


      .text1{
        position: static;
        display: block;
        top:auto;
        bottom:0rem;
        right:0px;
        left:0rem;
        text-align: center;
        margin-top:20px;
      }

      .text2{
        position: static;
        display: block;
        margin-bottom:20px;
        bottom:0rem;
        right:0;
        left:0;

      }

      .link{
        position: absolute;
        top:0;
        bottom:0;
        right:0;
        left:0;
      }

      h3{
        width: 100%;
        max-width: 100%;
        font-size: 40px;
        text-align: center;
        margin-top:10px;
        /* line-height: 0.9; */

      }



      .subtitle{ font-size: 20px;}
      a{ font-size:22px;}
    }
  }
}



@media (max-width: 576px) {


}
